import React from "react";
import profileImage from "../assets/profileimage.avif"
import { Link } from "react-router-dom";
import resumePDF from '../assets/ALEENA_ALBY.pdf'; 


function Home() {
  return (
    <div className="min-h-screen bg-white text-gray-900 p-8">
      {/* Header */}
      <header className="text-center mb-12">
      <img
          src={profileImage}
          alt="Profile"
          className="mx-auto w-32 h-32 rounded-full border border-gray-300 object-cover"
        />
        <div className="text-4xl font-bold">Aleena Alby</div>
        <div className="text-gray-700 mt-1">Software Engineer</div>
        <div className="flex justify-center mt-4 space-x-4">
          {/* <a href="#" className="text-gray-500 hover:text-black">
            Twitter
          </a> */}
          <a href="https://github.com/AleenaAlby" className="text-gray-500 hover:text-black">
            GitHub
          </a>
        </div>
      </header>

      <main className="max-w-2xl mx-auto">
        <p className="text-base leading-relaxed">Hi there!</p>
        <p className="text-base leading-relaxed mt-4">
                I'm Aleena, a <span className="text-red-500">software engineer </span> 
                building innovative solutions in   
                <a 
                    href="https://www.quera.com/glossary/quantum-computing-as-a-service-qcaas" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-red-500 hover:text-red-700 underline underline-offset-2"
                > Quantum Computing as a Service (QCaaS)
                </a>, 
                    where I get to combine cutting-edge technology with my passion for problem-solving.
                    My work revolves around making quantum computing more accessible and 
                    impactful for real-world applications.
        </p>
        <p className="text-base leading-relaxed mt-4">
        I graduated with a Master’s degree in Data Science and Computational Intelligence from Coventry University, England, in 2023.
        I conducted my dissertation under the supervision of<br></br>
        <a 
          href="https://www.linkedin.com/in/james-brusey-5543231/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-red-500 hover:text-red-700 underline underline-offset-2">  Dr. James Brusey
                </a>, focusing on the detection of Diabetic Retinopathy.
        </p>
        <p className="text-base leading-relaxed mt-4">
        The project involved diagnosing Diabetic Retinopathy using fundus images from a Kaggle dataset, 
        employing advanced deep learning models such as ResNet50, DenseNet201, EfficientNetV2L, and VGG19. 
        It included preprocessing techniques like CLAHE and data balancing methods to enhance model performance.
        </p>
        {/* List of Writings */}
        <div className="mt-12 text-base">
            <h2 className="text-gray-900">This is my journey:</h2>
            <h3 className="text-gray-900"><span className="text-red-500">Projects</span></h3>
            <div className="mt-2">
                <Link to="/facebook_photo_counter" className=" hover:underline underline underline-offset-2">
                Facebook photo counter
                </Link>
                <div className="text-sm text-gray-500">Nov 1, 2024</div>
            </div>
            
        </div>
        <div className="mt-12">
        <h3 className="text-gray-900"><span className="text-red-500">Contact</span></h3>
            <div className="mt-2">
                <p>
                Please use hello@aalby.com
                </p>
            </div>
            <div className="mt-2">
                <a
        href={resumePDF}  
        download="ALEENA_ALBY_Resume.pdf"         
        target="_blank"                  
        rel="noopener noreferrer"       
      >
        Click here to download my resume
      </a>
            </div>
          
        </div>
        
        {/* <div className="mt-12">
          <h2 className="text-2xl font-bold mb-4">Here are some things I've written:</h2>
          <ul className="space-y-4">
            <li>
              <a href="#" className="text-red-500 hover:underline">
                The Hole in the Sky That We Actually Fixed
              </a>
              <div className="text-sm text-gray-500">May 17, 2024</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                For Sale: A Promise to Remove Invisible Gas
              </a>
              <div className="text-sm text-gray-500">May 31, 2023</div>
            </li>
            <li>
              <a href="#" className="text-red-500 hover:underline">
                Debugging Misadventures: Down the Rabbit Hole
              </a>
              <div className="text-sm text-gray-500">May 15, 2023</div>
            </li>
          </ul>
        </div> */}
      </main>
    </div>
  );
}

export default Home;
