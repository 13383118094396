import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import FacebookPhotoCounter from "./pages/FacebookPhotoCounter";
import PhotosLayout from "./layouts/PhotosLayout";
import HorizonMap from "./pages/HorizonMap";
import TravelDiary from "./pages/TravelDiary";
import './index.css';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Routes>
        {/* Root Routes */}
        <Route path="/" element={<Home />} />
          <Route path="/facebook_photo_counter" element={<FacebookPhotoCounter />} />

          {/* Photos Routes with Layout */}
          <Route path="/photos" element={<PhotosLayout />} >
            {/* Subroute: /photos/vista */}
            <Route index element={<HorizonMap />} />
            {/* Separate route for /photos/vista */}
            <Route path="/photos/vista" element={<HorizonMap />} />
            <Route path="/photos/travel_diary" element={<TravelDiary />} />
          </Route>
          
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
