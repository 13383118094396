import React from 'react';

function Footer() {
  return (
    <footer className="text-center text-xs text-gray-500 py-4">
      <p>© 2024 <span className="font-semibold">Aleena Alby</span>. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
